<template>
  <div class="mb-50">
    <span class="fw-700">Thêm {{ nameType }} cho {{ arrPax.length }} hành khách:</span>
    <div :class="`ml-50 fw-700 text-warning ${isMobileView ? '' : 'font-small-4'}`">
      <span
        v-for="(pax, indexPax) of arrPax"
        :key="indexPax"
        class="mr-25"
      >
        {{ `${pax.lastName} ${pax.firstName}${(indexPax !== arrPax.length - 1) ? ',': '' }` }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    arrPax: {
      type: Array,
      default: () => [],
    },
    nameType: {
      type: String,
      default: () => '',
    },
  },
}
</script>
