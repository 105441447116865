var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-50"
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v("Thêm " + _vm._s(_vm.nameType) + " cho " + _vm._s(_vm.arrPax.length) + " hành khách:")]), _c('div', {
    class: "ml-50 fw-700 text-warning ".concat(_vm.isMobileView ? '' : 'font-small-4')
  }, _vm._l(_vm.arrPax, function (pax, indexPax) {
    return _c('span', {
      key: indexPax,
      staticClass: "mr-25"
    }, [_vm._v(" " + _vm._s("".concat(pax.lastName, " ").concat(pax.firstName).concat(indexPax !== _vm.arrPax.length - 1 ? ',' : '')) + " ")]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }